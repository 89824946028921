<template>
  <v-dialog
    v-model="showDialog"
    @click:outside="clickedOutside"
    @keydown.esc="clickedOutside"
    :persistent="persistent || saving"
    :max-width="previewUrl ? previewScreenScale.dialogWidth : '800px'"
    scrollable
  >
    <v-card
      :max-width="previewUrl ? previewScreenScale.formWidth : ''"
      :style="previewUrl ? 'border-top-right-radius: 0; border-bottom-right-radius: 0;' : ''"
    >
      <v-card-title class="py-3"> 
        {{ title }}

        <v-spacer />

        <v-icon @click="closeDialog">
          mdi-close
        </v-icon>
      </v-card-title>
    
      
      <v-card-text class="grey lighten-3 py-5">
        <component
          v-if="dynamicComponent !== null"
          :is="dynamicComponent"
          v-model="temporaryObject"
          @valid="setValid"
        />
      </v-card-text>

      <v-card-actions>
        <v-switch
          v-if="showActiveSwitch"
          :label="valueToMutateStatus ? $t('active'): $t('disabled')"
          v-model="valueToMutateStatus"
          color="green"
        />

        <v-spacer />
        
        <v-btn
          :disabled="saving || !valid"
          :loading="saving"
          color="success"
          @click="save"
        >
          {{ $t('save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-card
      v-if="previewUrl"
      class="pa-1"
      flat
      :max-width="previewScreenScale.cardWidth"
      style="border-top-left-radius: 0; border-bottom-left-radius: 0;"
    >
      <div
        style="font-weight: 700; font-size: 24px;"
        class="text-center py-3 text-capitalize"
      >
        {{ (this.previewType ? this.previewType : '') }} {{ $t('preview') }}
      </div>
      <div>
        <div style="display: flex; flex-direction: row;">
          <div style="flex-direction: column; width: 20px; height: 20px; background-color: black; border-top-left-radius: 50%;" />
          <div style="flex-direction: column; flex-grow: 1; height: 20px; background-color: black;" />
          <div style="flex-direction: column; width: 20px; height: 20px; background-color: black; border-top-right-radius: 50%;" />
        </div>
       
        <div style="display: flex; flex-direction: row;">
          <div style="flex-direction: column; width: 20px; background-color: black;" />
          <div
            style="flex-direction: column; flex-grow: 1; position: relative; width: 100%; height: 0; overflow: hidden;"
            :style="previewScreenScale.iframePaddingTop"
          >
            <div 
              ref="iframeContainer"
              style="position: absolute; top: 0; width: 100%; height: 100%;"
            >
              <v-progress-circular
                v-if="isLoading"
                indeterminate
                color="primary"
                class="loader"
              />
            </div>
          </div>
          <div style="flex-direction: column; width: 20px; background-color: black;" />
        </div>
        <div style="display: flex; flex-direction: row;">
          <div style="flex-direction: column; width: 20px; height: 20px; background-color: black; border-bottom-left-radius: 50%;" />
          <div style="flex-direction: column; flex-grow: 1; height: 20px; background-color: black;" />
          <div style="flex-direction: column; width: 20px; height: 20px; background-color: black; border-bottom-right-radius: 50%;" />
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
    name: 'MutationDialog',
    props: {
        objectToMutate: {
            type: Object,
            default: () => {}
        },
        formComponent: {
            type: String,
            required: true
        },
        saveCallback: {
          type: Function,
          required: true
        },
        title: {
          type: String,
          default: ''
        },
        previewUrl: {
          type: String,
          default: null
        },
        previewStyle: {
          type: String,
          default: null
        },
        previewType: {
            type: String,
            default: 'desktop'
        },
        persistent: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            temporaryObject: null,
            valid: false,
            showDialog: true,
            saving: false,
            isLoading: false,
            iframeWorker: null
        }
    },
    computed: {
        valueToMutateStatus: {
          get() {
            if (this.temporaryObject) {
              if (Object.prototype.hasOwnProperty.call(this.temporaryObject, 'active')) {
                return this.temporaryObject.active
              }
              if (Object.prototype.hasOwnProperty.call(this.temporaryObject, 'status')) {
                return this.temporaryObject.status
              }
            }
            return null
          },
          set(value) {
            if (this.temporaryObject) {
              if (Object.prototype.hasOwnProperty.call(this.temporaryObject, 'active')) {
                this.temporaryObject.active = value
                this.$emit('input', {
                  active: value,
                })
              }
              if (Object.prototype.hasOwnProperty.call(this.temporaryObject, 'status')) {
                this.temporaryObject.status = value
                this.$emit('input', {
                  status: value,
                })
              }
            }
          }
        },
        showActiveSwitch() {
          if (this.temporaryObject) {
            return Object.prototype.hasOwnProperty.call(this.temporaryObject, 'active') || Object.prototype.hasOwnProperty.call(this.temporaryObject, 'status')
          }
          return false
        },
        dynamicComponent() {
            return () => import(`@/${this.formComponent}.vue`)
        },
        previewUrlWithPreviewObject() {
          const url = this.previewUrl
          try {
            if(this.temporaryObject) {
              return url + '?previewDataObject=' + encodeURIComponent(JSON.stringify(this.temporaryObject))
            }
            return ''
          } catch (e) {
            return url
          }
        },
        previewScreenScale() {
            switch (this.previewType) {
                case 'mobile':
                    return { iframePaddingTop: 'padding-top: 177%;', formWidth: '50%', previewWidth: '50%', dialogWidth: '1000px'}
                case 'portrait':
                    return { iframePaddingTop: 'padding-top: 177%;', formWidth: '30%',  previewWidth: '70%', dialogWidth: '1800px'}
                case 'desktop':
                    return { iframePaddingTop: 'padding-top: 56%;', formWidth: '30%',  previewWidth: '70%', dialogWidth: '1800px'}
                default:
                    return { iframePaddingTop: 'padding-top: 56%;', formWidth: '30%',  previewWidth: '70%', dialogWidth: '1800px'}
            }
        }
    },
    methods: {
      setValid(value) {
        this.valid = value
      },
      clickedOutside() {
        if (this.persistent === false) {
          this.$store.commit('updateDataToMutate', null)
          this.showDialog = false
        }
      },
      closeDialog() {
        this.$store.commit('updateDataToMutate', null)
        this.showDialog = false
      },
      save() {
        this.saving = true
        // In error cases we want to make sure that the saving button is not endlessly loading.
        this.saveCallback(this.temporaryObject).then(result => {
          if (typeof result === 'boolean') {
            this.saving = result
          }
        })
      },
      initializeIframeWorker() {
        const workerBlob = new Blob([`
            self.onmessage = function(e) {
                const { url, action } = e.data;
                
                self.postMessage({ 
                    type: action,
                    url: url
                });
            };
        `], { type: 'application/javascript' })

        this.iframeWorker = new Worker(URL.createObjectURL(workerBlob))
        
        this.iframeWorker.onmessage = (e) => {
            const { type, url } = e.data
            
            if (type === 'create') {
                this.createIframe(url)
            } else if (type === 'update') {
                this.updateIframe(url)
            }
        }
      },

      createIframe(url) {
        const container = this.$refs.iframeContainer
        if (!container) return

        const iframe = document.createElement('iframe')
        iframe.src = url
        iframe.style.width = '100%'
        iframe.style.height = '100%'
        iframe.style.border = '0'
        
        iframe.onload = () => {
            this.isLoading = false
        }

        container.innerHTML = ''
        container.appendChild(iframe)
      },

      updateIframe(url) {
        const container = this.$refs.iframeContainer
        if (!container) return

        const iframe = container.querySelector('iframe')
        if (iframe) {
            iframe.onload = () => {
                this.isLoading = false
            }
            iframe.src = url
        } else {
            this.createIframe(url)
        }
      },

      loadIframe() {
        if (!this.previewUrlWithPreviewObject || !this.$refs.iframeContainer) return

        this.isLoading = true
        
        const action = this.$refs.iframeContainer.querySelector('iframe') 
            ? 'update' 
            : 'create'

        this.iframeWorker.postMessage({
            url: this.previewUrlWithPreviewObject,
            action: action
        })
      }
    },
    watch: {
        previewUrlWithPreviewObject: {
            handler(newVal) {
                if (newVal && this.$refs.iframeContainer) {
                    this.$nextTick(() => {
                        this.loadIframe()
                    })
                }
            },
            immediate: true
        }
    },
    mounted() {
      if(typeof this.objectToMutate !== 'undefined' && this.objectToMutate !== null) {
        try {
          this.temporaryObject = JSON.parse(JSON.stringify(this.objectToMutate))
        } catch (e) {
          this.temporaryObject = this.objectToMutate
        }
      }
      this.initializeIframeWorker()
    },
    beforeDestroy() {
        if (this.iframeWorker) {
            this.iframeWorker.terminate()
        }
    }
}
</script>

<style scoped>
.loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
</style>