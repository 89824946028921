<template>
  <v-list-item
    two-line
    link
    :key="item.uuid"
    @click="$emit('selectItem', item)"
  >
    <v-badge
      :color="badgeColor"
      bottom
      right
      dot
      offset-x="10"
      offset-y="10"
      class="mr-5"
    >
      <v-avatar size="40">
        <v-img
          v-if="thumbnail"
          :src="thumbnail"
        />
        <v-img
          v-else
          class="grey"
        />
      </v-avatar>
    </v-badge>
    <v-list-item-content>
      <v-list-item-title class="text-wrap text-break">
        {{ item.title }}
      </v-list-item-title>

      <v-list-item-subtitle v-if="item.sale_price === null || item.sale_price.amount === null || item.sale_price.amount === 0">
        <span class="green--text">{{ formatPrice(item.price) }}</span>
        <span v-if="item.cost_price !== null && item.cost_price.amount > 0">
          &mdash;
          <span class="blue--text"> {{ formatPrice(item.cost_price) }} </span> &mdash; <span> {{ profitMargin }}% </span>
        </span>
      </v-list-item-subtitle>

      <v-list-item-subtitle v-if="item.sale_price !== null && item.sale_price.amount > 0">
        <span class="green--text">{{ formatPrice(item.sale_price) }}</span> &mdash; <del class="red--text">{{ formatPrice(item.price) }}</del>
      </v-list-item-subtitle>

      <v-list-item-subtitle v-if="item.price.data.include_tax === null || item.price.data.include_tax === undefined">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              color="red"
              small
              class="ml-1"
              style="vertical-align: middle; margin-bottom: 2px;"
            >
              mdi-alert-circle
            </v-icon>
          </template>
          {{ $t('productMissingTaxBracketMessage') }}
        </v-tooltip>
        <span class="red--text"> {{ $t('missingTaxBracket') }} </span>
      </v-list-item-subtitle>

      <v-list-item-subtitle v-if="false">
        <span class="text--primary"> {{ item.sku }} </span> &mdash;
        <v-chip
          class="ma-1"
          x-small
        >
          {{ item.type }}
        </v-chip>
        <template v-for="category in item.categories">
          <v-chip
            :key="category.uuid"
            class="ma-1"
            x-small
          >
            {{ category.name }}
          </v-chip>
        </template>

      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action
      v-if="item.type === 'subscription' && item.isAutoRenewalEnabled === false"
      style="margin-right: 10px;"
    >
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
            icon
            v-on="on"
            link
            color="grey"
            style="height: 20px"
            @click.prevent=""
          >
            <v-icon color="grey">
              mdi-restart-off
            </v-icon>
          </v-btn>
        </template>
        {{ $t('autoRenewalOff') }}
      </v-tooltip>
    </v-list-item-action>
    <v-list-item-action
      v-if="item.type === 'subscription' && item.isAutoRenewalEnabled === true"
      style="margin-right: 10px;"
    >
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
            icon
            v-on="on"
            link
            color="grey"
            style="height: 20px"
            @click.prevent=""
          >
            <v-icon color="grey">
              mdi-restart
            </v-icon>
          </v-btn>
        </template>
        {{ $t('autoRenewalOn') }}
      </v-tooltip>
    </v-list-item-action>
    <v-list-item-content
      style="max-width: 150px;"
      v-if="false"
    >
      <v-progress-linear
        reactive
        :color="availabilityColor"
        height="15"
        v-model="currentAvailabilityInPercent"
        :test="currentAvailabilityInPercent"
      >
        <template v-slot="{ value }">
          <div class="overline">
            {{ Math.ceil(value) }}% | {{ item.availability.current }}
          </div>
        </template>
      </v-progress-linear>
    </v-list-item-content>

    <v-list-item-action
      style="margin-left: 5px;"
      v-if="false"
    >
      <v-btn
        link
        color="primary"
        icon
        @click="openSellOnlinePreview"
        @click.prevent=""
      >
        <v-icon color="blue">
          mdi-link
        </v-icon>
      </v-btn>
    </v-list-item-action>
    <v-list-item-action v-if="false">
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-badge
            bordered
            overlap
            :content="itemTranslatedContentWithNoEmpties.length"
            :value="itemHasTranslatedContent"
          >
            <v-btn
              icon
              @click="openTranslationDialog"
              @click.prevent=""
              v-on="on"
            >
              <v-icon :color="itemHasTranslatedContent ? 'primary' : 'grey lighten-1'">
                mdi-translate
              </v-icon>
            </v-btn>
          </v-badge>
        </template>
        {{ $t('translations') }}
      </v-tooltip>
    </v-list-item-action>
    <v-list-item-action
      class="mx-0"
      v-if="false"
    >
      <v-tooltip
        v-if="itemIsLocationBlocked.blocked"
        top
      >
        <template v-slot:activator="{ on }">
          <v-btn
            icon
            v-on="on"
          >
            <v-icon color="red">
              mdi-information
            </v-icon>
          </v-btn>
        </template>
        {{ itemIsLocationBlocked.message }}
      </v-tooltip>
      <v-btn
        v-else
        icon
      >
        <v-icon color="grey lighten-1">
          mdi-information
        </v-icon>
      </v-btn>
    </v-list-item-action>
    <TranslationDialog
      v-model="showTranslationDialog"
      v-if="showTranslationDialog"
      @closed="showTranslationDialog = false"
      :translate-item="item"
      @save="updateItemTranslations"
    />
    <BookingListDialog
      v-model="showBookingListDialog"
      v-if="showBookingListDialog"
      @closed="showBookingListDialog = false"
      @close="showBookingListDialog = false"
      :item="item"
      v-on="$listeners"
    />
  </v-list-item>
</template>

<script>
    import TranslationDialog from "@/components/translation/TranslationDialog"
    import BookingListDialog from "@/components/booking/BookingListDialog"

    export default {
        name: 'ItemListItem',
        components: {
            TranslationDialog,
            BookingListDialog
        },
        props: {
            item: {
                type: Object,
                required: true,
                default: () => {}
            }
        },
        data: () => ({
            showItemDialog: false,
            showTranslationDialog: false,
            showBookingListDialog: false,
            selectedItem: null,
            bookingCount: null,
            gettingBookingCount: false
        }),
        computed: {
            organizationUUID() {
                return this.$store.state.organizationUUID
            },
            itemHasTranslatedContent() {
                return this.item && Array.isArray(this.item.translatedContent) && this.item.translatedContent.length > 0
            },
            itemTranslatedContent() {
                if(this.itemHasTranslatedContent) {
                    return this.item.translatedContent
                }
                return []
            },
            itemTranslatedContentWithNoEmpties() {
                return this.itemTranslatedContent.filter(translation => translation !== null && translation !== undefined && translation.value !== null && translation.value !== undefined && translation.value !== '')
            },
            availabilityColor() {

                if(this.item.availability === null || this.item.availability === undefined) {
                    return 'green'
                }

                if(this.item.availability.infinite) {
                    return 'blue'
                }

                if(this.item.availability.current === 0) {
                    return 'red'
                }

                return this.item.availability.current < this.item.availability.threshold ? 'orange' : 'green'
            },
            currentAvailabilityInPercent() {

                if(this.item.availability === null || this.item.availability === undefined) {
                    return 100
                }

                if(this.item.availability.current === 0) {
                    return 0
                }

                if(this.item.availability.infinite) {
                    return 100
                }

                return this.item.availability.target / this.item.availability.current
            },
            badgeColor() {
                return this.item.status ? 'green' : 'red'
            },
						itemLocationBlocks() {
                return this.$store.state.itemLocationBlocks
						},
            itemIsLocationBlocked() {
								if(!Array.isArray(this.itemLocationBlocks) || this.itemLocationBlocks.length < 1) {
								    return {
								        blocked: false,
												message: this.$t('itemIsNotBlocked')
										}
								}

								const currentItemBlock = this.itemLocationBlocks.find(b => {
                    return b.item === this.item.uuid
                })
								if(currentItemBlock !== null && typeof currentItemBlock !== 'undefined') {
								    const location = this.$store.state.locations.find(l => {
								        return l.uuid === currentItemBlock.location
										})
								    return {
                        blocked: true,
												location: currentItemBlock.location,
                        message: this.$t('itemHasBeenBlockedInTheTabletOn') + ' ' + location.label
                    }
								}
                return {
                    blocked: false,
                    message: this.$t('itemIsNotBlocked')
                }
            },
            profitMargin() {

                if(this.item.cost_price && this.item.cost_price.amount > 0) {
                    return (((this.item.price.amount -this.item.cost_price.amount) / this.item.price.amount) * 100).toFixed(2)
                }

                return 0
            },
            categoryNames() {
                let names = []

                if(this.item.categories && this.item.categories.length > 0) {
                    for(let i in this.item.categories) {
                        names.push(this.item.categories[i].name)
                    }
                }

                return names.join(', ')
            },
            thumbnail() {

                if(this.item.images && this.item.images[0] && this.item.images[0].file) {
                    return this.item.images[0].file.sources.thumbnail
                }

                return null
            }
        },
        methods: {
          formatPrice(price) {
            return this.$store.getters.formatAmountByCurrency(price)
          },
            openBookingListDialog() {
                this.showBookingListDialog = !this.showBookingListDialog
            },
            openSellOnlinePreview() {
                this.$emit('sellOnlinePreview', this.item)
            },
            openTranslationDialog() {
                this.showTranslationDialog = !this.showTranslationDialog
            },
            updateItemTranslations(translations) {
                const payload = {
                    item: this.item,
                    translations: translations
                }
                this.$store.dispatch('updateItemTranslations', payload).then(response => {
                    if(response && response.success === true) {
                        this.showTranslationDialog = false
                    }
                })
            },
            convertCurrencies(price) {
                if(typeof price !== 'undefined' && price !== null) {
                    if(typeof price.currency_code !== 'undefined' && price.currency_code !== null) {
                        const returnable = JSON.parse(JSON.stringify(price))
                        if(returnable.currency_code !== 'ISK') {
                            returnable.amount = Math.round(((returnable.amount / 100) + Number.EPSILON) * 100) / 100
                        }
                        return returnable
                    }
                }
                return price
            }
        }
    }
</script>
