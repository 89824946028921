<template>
  <ExtendedView :tabs="tabs">
    <template v-slot:list="{ item }">
      <OrderList
        v-if="item.key === 'all'"
        :length="100"
        :step="100"
        :fixed-filters="newOrdersFilter"
        @select-order="selectOrder"
        :load-items-callback="getOrders"
      />
      <OrderList
        v-if="item.key === 'new'"
        :length="100"
        :step="100"
        :fixed-filters="newOrdersFilter"
        @select-order="selectOrder"
        :load-items-callback="getOrders"
      />
      <OrderList
        v-if="item.key === 'pending'"
        :length="100"
        :step="100"
        :fixed-filters="pendingOrdersFilter"
        @select-order="selectOrder"
        :load-items-callback="getOrders"
      />
      <OrderList
        v-if="item.key === 'processing'"
        :length="100"
        :step="100"
        :fixed-filters="processingOrdersFilter"
        @select-order="selectOrder"
        :load-items-callback="getOrders"
      />
      <OrderList
        v-if="item.key === 'completed'"
        :length="100"
        :step="100"
        :fixed-filters="completedOrdersFilter"
        @select-order="selectOrder"
        :load-items-callback="getOrders"
      />
      <OrderList
        v-if="item.key === 'delivered'"
        :length="100"
        :step="100"
        :fixed-filters="deliveredOrdersFilter"
        @select-order="selectOrder"
        :load-items-callback="getOrders"
      />
      <OrderList
        v-if="item.key === 'pendingPayment'"
        :length="100"
        :step="100"
        :fixed-filters="pendingPaymentOrdersFilter"
        @select-order="selectOrder"
        :load-items-callback="getOrders"
      />
      <OrderList
        v-if="item.key === 'canceled'"
        :length="100"
        :step="100"
        :fixed-filters="canceledOrdersFilter"
        @select-order="selectOrder"
        :load-items-callback="getOrders"
      />
      <OrderList
        v-if="item.key === 'cart'"
        :length="100"
        :step="100"
        :fixed-filters="cartOrdersFilter"
        @select-order="selectOrder"
        :load-items-callback="getOrders"
      />
    </template>
    <template slot="profile">
      <OrderProfile
        v-if="selectedOrder"
        :key="selectedOrder.uuid"
        :order="selectedOrder"
      />
    </template>
  </ExtendedView>
</template>

<script>
  import ExtendedView from '@/components/common/ExtendedView'
  import OrderProfile from '@/components/order/OrderProfile'
  import OrderList from '@/components/OrderList'
  export default {
    name: 'Orders',
    components: {
      ExtendedView,
      OrderProfile,
      OrderList
    },
    data: () => ({
      selectedOrder: null,
      allOrdersFilter: [
        {
          key: 'state',
          value: 'cart',
          operator: 'equals'
        }
      ],
      newOrdersFilter: [
        {
          key: 'state',
          value: 'new',
          operator:  'equals'
        },
      ],
	    pendingOrdersFilter: [
		    {
			    key: 'state',
			    value: 'billing_pending_payment',
			    operator:  'equals'
		    },
	    ],
      processingOrdersFilter: [
        {
          key: 'state',
          value: 'processing',
          operator: 'equals'
        }
      ],
      completedOrdersFilter: [
        {
          key: 'state',
          value: 'completed',
          operator: 'equals'
        }
      ],
      deliveredOrdersFilter: [
        {
          key: 'state',
          value: 'delivered',
          operator: 'equals'
        }
      ],
      collectingPayments: [
        {
          key: 'state',
          value: 'collectingPayments',
          operator: 'equals'
        }
      ],
      collectingLineItems: [
        {
          key: 'state',
          value: 'collectingLineItems',
          operator: 'equals'
        }
      ],
      canceledOrdersFilter: [
        {
          key: 'state',
          value: 'canceled',
          operator: 'equals'
        }
      ],
      pendingPaymentOrdersFilter: [
        {
          key: 'state',
          value: 'billing_pending_payment',
          operator: 'equals'
        }
      ],
      cartOrdersFilter: [
        {
          key: 'state',
          value: 'cart',
          operator: 'equals'
        }
      ]
    }),
    methods: {
      selectOrder(order) {
        this.$store.commit('updateSelectedOrder', order)
        this.selectedOrder = order
      },
      startOfDay(timestamp) {
        return new Date(timestamp).setHours(0,0,0,0)
      },
      endOfDay(timestamp) {
        return new Date(timestamp).setHours(23,59,59,999)
      },
      getOrders(payload) {
        return this.$store.dispatch('getOrders', payload).then(orders => {
			if(this.selectedOrder) {
				const foundOrder = orders.find(order => order && order.uuid === this.selectedOrder.uuid)
				if(foundOrder) {
					this.selectedOrder = foundOrder
				} else {
					this.selectedOrder = null
				}
			}
			return orders
        })
      }
    },
    computed: {
      selectedAppBarTab: {
        set(newValue) {
          this.$store.commit('updateSelectedAppBarTab', newValue)
        },
        get() {
          return this.$store.state.selectedAppBarTab
        }
      },
      startTimeInMillis: {
        set(value) {
          this.$store.commit('updateStartTimeInMillis', value)
        },
        get() {
          return this.$store.state.startTimeInMillis
        }
      },
      endTimeInMillis: {
        set(value) {
          this.$store.commit('updateEndTimeInMillis', value)
        },
        get() {
          return this.$store.state.endTimeInMillis
        }
      },
      /* Þarf að útfæra sem filter.
      sentToExternalAccounting() {
        if (this.orders === null) {
          return null
        }

        return this.orders.filter(order => {
          let result = false

          if (order === null) {
            return false
          }

          if (order.externalDeliveryResults === null || order.externalDeliveryResults === undefined) {
            return false
          }

          if (order.externalDeliveryResults.length < 1) {
            return false
          }

          for (let index in order.externalDeliveryResults) {
            // Find one single instance of this namespace where the result is true, if it's not true, then just return true if this for loop finishes, return false
            result = order.externalDeliveryResults.find(result => result.namespace === order.externalDeliveryResults[index].namespace && result.success)
            if (result === null || result === undefined) {
              return false
            }
            else if (result.success !== true) {
              return false
            }
          }

          return true
        })
      },

      notSentToExternalAccounting() {
        if (this.orders === null) {
          return null
        }

        return this.orders.filter(order => {
          let result = false

          if (order === null) {
            return null
          }

          if (order.externalDeliveryResults === null || order.externalDeliveryResults === undefined) {
            return true
          }

          if (order.externalDeliveryResults.length < 1) {
            return true
          }

          // namespace == dkplus , Inexchange, VISTA, ......

          // Every single namespace in the externalDeliveryResults needs to be true atleast once in order to return false
          for (let index in order.externalDeliveryResults) {
            // Find one single instance of this namespace where the result is true, if it's not true, then just return true if this for loop finishes, return false
            result = order.externalDeliveryResults.find(result => result.namespace === order.externalDeliveryResults[index].namespace && result.success)
            if (result === null || result === undefined) {
              return true
            }
            else if (result.success === false) {
              return true
            }
          }

          return false

        })

      },*/

      tabs() {
        const tabs = []

        tabs.push({
          title: this.$t('all'),
          key: 'all'
        })

        tabs.push({
          title: this.$t('new'),
          key: 'new'
        })

	      tabs.push({
		      title: this.$t('pending'),
		      key: 'pending'
	      })

        tabs.push({
          title: this.$t('processing'),
          key: 'processing'
        })

        tabs.push({
          title: this.$t('completed'),
          key: 'completed'
        })

        tabs.push({
          title: this.$t('delivered'),
          key: 'delivered'
        })

	      tabs.push({
		      title: this.$t('Pending payment'),
		      key: 'pendingPayment'
	      })

        tabs.push({
          title: this.$t('cancelled'),
          key: 'canceled'
        })

        tabs.push({
          title: this.$t('cart'),
          key: 'cart'
        })

        //vantar filter virkni sem meðhöndlar þetta
        /*
        tabs.push({
          title: 'Sent To External Accounting',
          key: 'sentToExternalAccounting'
        })

        tabs.push({
          title: 'Not Sent To External Accounting',
          key: "notSentToExternalAccounting"
        })
        */
        return tabs
      }
    },
    mounted() {
      this.$store.commit('updateAppBarTabs', this.tabs)
      this.$store.commit('updateSelectedAppBarTab', this.tabs[0].key)
    }
  }
</script>
