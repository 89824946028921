<template>
  <div>
    <SimpleCustomerSearchDialog
      v-if="showCustomerSearch"
      :show-dialog="showCustomerSearch"
      :current-customer="customer"
      @close="closeCustomerSearch"
      @updateCustomer="updateCustomerValue"
    />
    <v-card
      flat
      :loading="isFetching"
      tile
      outlined
    >
      <v-toolbar
        flat
        class="pa-2"
      >
        <v-toolbar-title>
          <v-tooltip
            bottom
            color="black"
          >
            <template v-slot:activator="{ on, attrs }">
              <div
                class="profile-header-caption"
                v-on="on"
                v-bind="attrs"
              >
                {{ orderUUID }}
              </div>
            </template>
            <span>
              {{ $t('Order') }} {{ $t('UUID') }}
            </span>
          </v-tooltip>
          <div class="profile-header-title">
            {{ $t('order') }} #{{ orderId }}
            <v-tooltip
              bottom
              color="black"
            >
              <template v-slot:activator="{ on, attrs }">
                <span
                  v-on="on"
                  v-bind="attrs"
                >{{ sequenceNumber ? `- ${sequenceNumber}` : '' }}</span>
              </template>
              <span>
                {{ $t('Sequence') }}
              </span>
            </v-tooltip>
          </div>
          <div
            v-if="channelName || (order && order.origin)"
            class="profile-header-subtitle"
          >
            <v-tooltip
              bottom
              color="black"
            >
              <template v-slot:activator="{ on, attrs }">
                <span
                  v-if="channelName"
                  v-on="on"
                  v-bind="attrs"
                >{{ channelName }}</span>
              </template>
              <div>
                <div>
                  {{ $t('Channel') }}
                </div>
              </div>
            </v-tooltip>
            <span v-if="channelName && order && order.origin">
              -
            </span>
            <v-tooltip
              bottom
              color="black"
            >
              <template v-slot:activator="{ on, attrs }">
                <span
                  v-if="order && order.origin"
                  v-on="on"
                  v-bind="attrs"
                  class="text-capitalize"
                >
                  {{ order.origin }}
                </span>
              </template>
              <span>
                {{ $t('Order origin') }}
              </span>
            </v-tooltip>
          </div>
        </v-toolbar-title>
        <v-chip
          v-if="order && order.locationVariation"
          class="ml-5"
        >
          {{ order.locationVariation.label }}
        </v-chip>
        <v-spacer />
        <div>
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-on="on"
                v-bind="attrs"
                elevation="0"
                class="mr-3"
                link
                target="_blank"
                :href="legacyLink"
              >
                {{ $t('View in Legacy') }}
              </v-btn>
            </template>
            <span>
              {{ $t('View in Legacy') }}
            </span>
          </v-tooltip>
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="order.balance.amount > 0"
                color="primary"
                v-on="on"
                v-bind="attrs"
                @click="showPaylinkDialog"
              >
                {{ $t('paylink') }}
              </v-btn>
            </template>
            <span>
              {{ $t('Send a PayLink to Customer') }}
            </span>
          </v-tooltip>
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="order.balance.amount <= 0"
                color="primary"
                v-on="on"
                v-bind="attrs"
                @click="showReceiptDialog"
              >
                {{ $t('receipt') }}
              </v-btn>
            </template>
            <span>
              {{ $t('Send Receipt to Customer') }}
            </span>
          </v-tooltip>
        </div>
      </v-toolbar>
      <v-card-text>
        <v-container
          fluid
          class="py-0 px-2 relative"
        >
          <v-row
            v-if="userIsAdmin && false"
            justify="end"
            class="caption absolute-top-right px-2"
          >
            <v-col
              cols="auto"
              class="inter-font"
            >
              {{ order.uuid }}
            </v-col>
          </v-row>
          <v-row justify="end">
            <v-col
              cols="auto"
              v-if="order && order.coupon"
            >
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-chip
                    v-on="on"
                    v-bind="attrs"
                    label
                    outlined
                    class="inter-font"
                    style="background-color: white!important;"
                    @click="selectCoupon"
                  >
                    <span><span class="text--button-small">{{ $t('Coupon') }}</span> <span>|</span> <span>{{ order.coupon.label ? order.coupon.label : order.coupon.code }}</span></span>
                  </v-chip>
                </template>
                <span>
                  {{ $t('Coupon') }} {{ $t('used with order') }}
                </span>
              </v-tooltip>
            </v-col>
          </v-row>
          <v-row justify="space-between">
            <v-col
              cols="12"
              md="6"
              lg="4"
            >
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <div
                    class="profile-order-customer-label"
                    v-on="on"
                    v-bind="attrs"
                    @click="openCustomerSearch"
                  >
                    {{ $t('Customer') }}
                    <v-icon
                      small
                      color="black"
                    >
                      mdi-pencil-outline
                    </v-icon>
                  </div>
                </template>
                <span>
                  {{ $t('Change Customer On Order') }}
                </span>
              </v-tooltip>
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <div
                    class="profile-order-customer-name"
                    :class="!customer ? 'enforce-default-pointer black--text' : 'primary--text'"
                    v-on="!customer ? null : on"
                    v-bind="!customer ? null : attrs"
                    @click="selectCustomer"
                  >
                    {{ customerName }}
                  </div>
                </template>
                <span>
                  {{ $t('View Customer Info') }}
                </span>
              </v-tooltip>
            </v-col>
            <v-col
              v-if="order && order.balance && order.balance.amount !== 0"
              cols="12"
              md="6"
              xl="4"
              class="align-start"
            >
              <v-alert
                class="mb-0"
                outlined
              >
                <template v-slot:prepend>
                  <v-icon
                    color="warning"
                    class="mr-3"
                    size="36"
                  >
                    mdi-alert
                  </v-icon>
                </template>
                <template v-slot:default>
                  <div class="text-end">
                    <div>
                      {{ $t('This order has an imbalance of') }}
                    </div>
                    <div class="font-weight-bold text-h5">
                      {{ $store.getters.formatAmountByCurrency(order.balance) }}
                    </div>
                    <div
                      v-if="order.balance.amount > 0"
                      class="text-caption text-uppercase font-weight-bold warning--text"
                      style="line-height: 0.8em;"
                    >
                      {{ $t('Unpaid') }}
                    </div>
                    <div
                      v-else-if="order.balance.amount < 0"
                      class="text-caption text-uppercase font-weight-bold warning--text"
                      style="line-height: 0.8em;"
                    >
                      {{ $t('Over-paid') }}
                    </div>
                  </div>
                </template>
              </v-alert>
            </v-col>
          </v-row>
          <v-row
            v-if="order && order.locationVariation && false"
            dense
            justify="end"
          >
            <v-col cols="auto">
              <v-chip
                label
                outlined
                color="primary"
                class="profile-table-text inset-shadow-3"
                :ripple="false"
              >
                {{ order.locationVariation.label }}
              </v-chip>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-tabs v-model="selectedTab">
        <v-tab
          v-for="tab in tabs"
          :key="tab.key"
          :href="'#' + tab.key"
        >
          {{ tab.title }}
        </v-tab>
      </v-tabs>
    </v-card>
    <v-tabs-items
      class="gray lighten-3 transparent"
      v-model="selectedTab"
    >
      <v-tab-item
        v-for="tab in tabs"
        :key="tab.key"
        :value="tab.key"
        class="transparent"
      >
        <v-card 
          v-if="tab.key === 'tasks' && (typeof tasks === 'undefined' || tasks === null)"
          class="transparent"
          outlined
        >
          <v-row dense>
            <v-col cols="12">
              <v-card
                outlined
              >
                <v-progress-linear
                  indeterminate
                />
              </v-card>
            </v-col>
          </v-row>
        </v-card>
        <v-card
          v-if="tab.key === 'tasks'"
          class="transparent"
          outlined
        >
          <v-row dense>
            <v-col cols="12">
              <v-card outlined>
                <OrderTaskList
                  :tasks="tasks"
                />
              </v-card>
            </v-col>
          </v-row>
        </v-card>
        <v-card
          v-if="tab.key === 'view' && order.lineItems"
          class="transparent"
          outlined
        >
          <v-row
            dense
            v-if="shippingInformationData"
          >
            <v-col cols="12">
              <v-card
                class="mt-2"
                flat
                outlined
              >
                <v-card-title>
                  {{ $t('Shipping information') }} <v-spacer /> <v-tooltip
                    bottom
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-on="on"
                        v-bind="attrs"
                        small
                        class="mt-0 pt-0"
                      >
                        mdi-help-circle-outline
                      </v-icon>
                    </template>
                    <span>
                      {{ $t('This is the shipping information the customer provided during checkout') }}
                    </span>
                  </v-tooltip>
                </v-card-title>
                <v-card-text>
                  <div>
                    <div class="font-weight-bold text-subtitle-1">
                      {{ $t('name') }}
                    </div>
                    <div>
                      {{ shippingInformationData?.name }}
                    </div>
                  </div>
                  <div class="pt-2">
                    <div class="font-weight-bold text-subtitle-1">
                      {{ $t('address') }}
                    </div>
                    <div>
                      {{ shippingInformationData?.address }}
                    </div>
                    <div>
                      {{ shippingInformationData?.postal_code }} {{ shippingInformationData?.locality }}
                    </div>
                    <div>
                      {{ shippingInformationData?.country }}
                    </div>
                  </div>
                  <v-divider class="my-2" />
                  <div>
                    {{ $t('Phone') }}: {{ shippingInformationData?.phone }}
                  </div>
                  <div>
                    {{ $t('Email') }}: {{ shippingInformationData?.email }}
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <v-card
                v-if="order"
                class="mt-2"
                flat
                outlined
              >
                <LineItemList
                  class="mt-3"
                  :line-items="order.lineItems"
                  :coupon="order.coupon"
                  :coupon-calculation="order.couponCalculation"
                />
                <v-divider class="mx-4" />
                <div 
                  v-if="Array.isArray(order.lineItems) && order.lineItems.length > 0"
                >
                  <v-row
                    v-for="(currency, index) in orderSubTotalsPerCurrency"
                    :key="order.uuid + ':' + index + ':subTotal:' + currency.subTotal"
                    class="justify-space-around text-left mx-2"
                  >
                    <v-col
                      cols="6"
                    >
                      <!-- Needs to be empty to match columns with LineItemListItem-->
                    </v-col>
                    <v-col
                      cols="2"
                      style="opacity: 35%;"
                      class="text-subtitle-2"
                    >
                      {{ $t('subTotal') }}
                    </v-col>
                    <v-col
                      cols="2"
                    >
                      {{ currency.subTotal }}
                    </v-col>
                    <v-col
                      cols="1"
                    >
                      <!-- Needs to be empty to match columns with LineItemListItem-->
                    </v-col>
                  </v-row>
                  <v-row 
                    v-for="(currency, index) in orderSubTotalsPerCurrency"
                    :key="order.uuid + ':' + index + ':taxTotal:' + currency.taxTotal"
                    class="justify-space-around text-left mx-2"
                  >
                    <v-col
                      cols="6"
                      v-if="currency"
                    >
                      <!-- Needs to be empty to match columns with LineItemListItem-->
                    </v-col>
                    <v-col
                      v-if="currency"
                      cols="2"
                      style="opacity: 35%;"
                      class="text-subtitle-2"
                    >
                      {{ currency.displayTitle }}
                    </v-col>
                    <v-col
                      v-if="currency"
                      cols="2"
                    >
                      {{ currency.taxTotal }}
                    </v-col>
                    <v-col
                      v-if="currency"
                      cols="1"
                    >
                      <!-- Needs to be empty to match columns with LineItemListItem-->
                    </v-col>
                  </v-row>
                  <v-divider class="mx-4" />
                  <v-row 
                    v-for="(currency, index) in orderSubTotalsPerCurrency"
                    :key="order.uuid + ':' + index + ':orderTotal:' + order.orderTotal"
                    class="justify-space-around text-left mx-2"
                  >
                    <v-col
                      cols="6"
                    >
                      <!-- Needs to be empty to match columns with LineItemListItem-->
                    </v-col>
                    <v-col
                      cols="2"
                      style="opacity: 35%;"
                      class="text-subtitle-2"
                    >
                      {{ $t('orderTotal') }}
                    </v-col>
                    <v-col
                      cols="2"
                      class="font-weight-bold"
                    >
                      {{ currency.orderTotal }}
                    </v-col>
                    <v-col
                      cols="1"
                    >
                      <!-- Needs to be empty to match columns with LineItemListItem-->
                    </v-col>
                  </v-row>
                </div>
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-card
                flat
                outlined
              > 
                <v-card-title class="text-h6">
                  {{ $t('payments') }}
                </v-card-title>
                <SimplifiedPaymentsList
                  v-if="tab.key === 'view' && order.payments"
                  :key="order.uuid"
                  :fixed-filters="orderPaymentsFilters"
                  :load-items-callback="getPayments"
                  @selectPayment="selectPayment"
                />
                <PaymentList
                  v-if="tab.key === 'view' && order.payments && false"
                  :key="order.uuid"
                  :fixed-filters="orderPaymentsFilters"
                  :load-items-callback="getPayments"
                  @selectPayment="selectPayment"
                  :show-search="false"
                />
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-card
                v-if="tab.key !== 'tasks' && order && order.externalDeliveryResults"
                class="mt-2"
                flat
                outlined
              >
                <ExternalDeliveryResults :results="order.externalDeliveryResults" />
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>

import LineItemList from '@/components/lineItem/LineItemList'
import PaymentList from '@/components/PaymentList'
import ExternalDeliveryResults from '@/components/externalDeliveryResults/ExternalDeliveryResults'
import OrderTaskList from '@/components/order/OrderTaskList'
import SimplifiedPaymentsList from "@/components/payment/SimplifiedPaymentsList"
import SimpleCustomerSearchDialog from "@/components/customer/SimpleCustomerSearchDialog"

export default {
    name: 'OrderProfile',
    props: {
      order: {
        type: Object,
        default: () => null
      },
      entangleKey: {
        type: String,
        default: () => null
      }
    },
    data() {
      return {
        tasks: null,
        selectedTab: 'lineItems',
				isFetching: false,
	      showCustomerSearch: false
      }
    },
    components: {
	    SimpleCustomerSearchDialog,
	    SimplifiedPaymentsList,
	    LineItemList,
        PaymentList,
        ExternalDeliveryResults,
        OrderTaskList
    },
    methods: {
		selectCoupon() {
			if(typeof this.order === 'undefined' || this.order === null) {
				return
			}

			if(typeof this.order.coupon === 'undefined' || this.order.coupon === null) {
				return
			}

			this.$store.commit('updateContextOverlay', {
				'component':  'components/coupon/CouponProfile',
				'props': {
					'coupon': this.order.coupon
				}
			})
		},
      openCustomerSearch() {
        this.showCustomerSearch = true
      },
	    closeCustomerSearch() {
		  this.showCustomerSearch = false
	    },
	    updateCustomerValue(customer) {
		  if(typeof this.order === 'undefined' || this.order === null) {
			  this.$store.commit('updateActionError', {
				  message: this.$t('Oopsie woopsie'),
				  subMessage: this.$t('Missing order information')
			  })
		  }
		  if(typeof customer === 'undefined') {
			  this.$store.commit('updateActionError', {
				  message: this.$t('Oopsie woopsie'),
				  subMessage: this.$t('Customer has to be either object or null')
			  })
		  }

		  this.$store.dispatch('updateCustomerOnOrder', { order: this.order, customer: customer }).then(result => {
			  if(result) {
				  if(result.success === true) {
					  this.$store.commit('updateActionSuccess', {
						  message: this.$t('Success'),
						  subMessage: this.$t('Successfully updated the customer on order #', { orderId: this.order.orderId })
					  })
				  } else {
					  this.$store.commit('updateActionError', {
						  message: this.$t('Failure'),
						  subMessage: result.message
					  })
				  }
			  } else {
				  this.$store.commit('updateActionError', {
					  message: this.$t('Oopsie woopsie'),
					  subMessage: this.$t('Did not update the customer on the order')
				  })
			  }
		  }).catch(() => {
			  this.$store.commit('updateActionError', {
				  message: this.$t('Error'),
				  subMessage: this.$t('Encountered an error while trying to update customer on this order')
			  })
		  }).finally(() => {
			  this.closeCustomerSearch()
		  })
	    },
      getOrder() {
				this.isFetching = true
        const orderUUID = this.entangleKey.split(':')[0]
        this.$store.dispatch('getOrderByUUID', {uuid: orderUUID}).then(order => {
          this.order = order
        }).finally(() => {
					this.isFetching = false
				})
      },
        selectCustomer() {
		  if(typeof this.order === 'undefined' || this.order === null) {
			  return
		  }
		  if(typeof this.order.customer === 'undefined' || this.order.customer === null) {
			  return
		  }
			this.$store.commit('updateContextOverlay', {
				'component': 'components/customer/CustomerProfile',
				'props': {
					'customer': this.order.customer
				}
			})
        },
      selectPayment(payment) {
        this.$store.commit('updateContextOverlay', {
          'component':  'components/payment/PaymentProfile',
          'props': {
            'payment': payment
          }
        })
      },
      getPayments({limit, skip, conditions}) {
        return this.$store.dispatch('getPayments', {limit: limit, conditions})
      },
      getOrderTasks() {
        if(!this.order) {
          this.tasks = []
          return null
        }
        if ( typeof this.order.entangleKey !== 'undefined' && this.order.entangleKey !== null && this.order.entangleKey !== '' ) {
          return this.$store.dispatch('getTasksByEntangleKey', {entangleKey: this.order.entangleKey})
              .then(tasks => {
                if ( Array.isArray(tasks) && tasks.length > 0 ) {
                  this.tasks = tasks
                }
                else {
                  this.tasks = []
                }
              })
              .catch(e => {
                console.log('Error fetching tasks:', e)
              })
        }
      },
      showReceiptDialog() {
        this.$store.commit('updateGenericDialog', {
          'component': 'components/order/OrderReceiptCard', 
          'props': {
            'order': this.order
          }
        })
      }, 
      showPaylinkDialog() {
        this.$store.commit('updateGenericDialog', {
          'component': 'components/order/OrderPaylinkCard', 
          'props': {
            'order': this.order
          }
        })
      }

    },
    computed: {
      orderData() {
        if(typeof this.order?.data === 'string') {
          return JSON.parse(this.order.data)
        }
        return this.order?.data ?? null
      },
      checkoutSectionInstancesData() {
        if(!Array.isArray(this.orderData?.checkoutSectionInstancesData)) {
          return null
        }
        const data = []
        for (const sectionData of this.orderData?.checkoutSectionInstancesData) {
          if(typeof sectionData?.data === 'string') {
            data.push({...sectionData, data: JSON.parse(sectionData.data)})
          }
        }
        return data
      },
      shippingInformationData() {
        const shippingInformation = this.checkoutSectionInstancesData?.find(section => section.namespace === 'customer_profile_shipping')
        if(!shippingInformation) {
          return null
        }
        if(!shippingInformation?.data?.billing_is_shipping) {
          return shippingInformation.data
        }

        const billingInformation = this.checkoutSectionInstancesData?.find(section => section.namespace === 'customer_profile_billing')
        if(!billingInformation) {
          return shippingInformation.data
        }
        return billingInformation.data
      },
		sequenceNumber() {
			const sequence = this.order?.sequence ?? null
			return sequence !== '0' ? sequence : null
		},
		orderUUID() {
			if(typeof this.order === 'undefined' || this.order === null) {
				return null
			}
			
			return this.order.uuid
		},
		legacyLink() {
			if(this.orderId === null) {
				return ''
			}
			return `https://admin.salescloud.is/sales/${this.orderId}`
		},
		userIsAdmin() {
			return this.$store.getters.userIsAdmin
		},
	    customer() {
			if(typeof this.order === 'undefined' || this.order === null) {
				return null
			}
			if(typeof this.order.customer === 'undefined' || this.order.customer === null) {
				return null
			}
			return this.order.customer
	    },
		customerName() {
			if(this.customer === null) {
				return 'Anonymous'
			}
			if(typeof this.customer.address === 'undefined' || this.customer.address === null) {
				return ''
			}
			if(typeof this.customer.address.name_line === 'undefined' || this.customer.address.name_line === null) {
				return ''
			}

			return this.customer.address.name_line
		},
      orderPaymentsFilters() {
        return [
          {
            key: 'orderId',
            value: this.order.orderId,
            operator: 'equals',
						type: 'text'
          }
        ]
      },
      channelName() {
		  if(typeof this.order === 'undefined' || this.order === null) {
			  return ''
		  }
		  if(typeof this.order.channel === 'undefined' || this.order.channel === null) {
			  return this.$t('Created in back-office')
		  }
		  if(typeof this.order.channel.label === 'undefined' || this.order.channel.label === null) {
			 return this.$t('Channel has no label')
		  }
        return this.order.channel.label
      },
      orderId() {
        if (this.order === null || this.order.orderId === null) {
          return ''
        }
        return this.order.orderId
      },
      tabs() {
        return [
          {
            title: this.$t('view'),
            key: 'view'
          },
          {
            title: this.$t('tasks'),
            key: 'tasks'
          },
        ]
      },
      selectedOrder() {
        return this.$store.state.selectedOrder
      },
      orderSubTotalsPerCurrency() {
        //TODO:: Should possibly put this in the store or in the paas but didn't want to chain getters or muddle getOrders further. -jmc
        let subTotal = 0
        let taxTotal = 0
        const currencies = {}
        const order = this.selectedOrder || this.order
        if(typeof order !== 'undefined' && order !== null && Array.isArray(order.lineItems)) {
          for(let itemKey = 0; itemKey < order.lineItems.length; itemKey++) {
            const lineItem = order.lineItems[itemKey]
            if(!lineItem) {
				continue
            }
            if(typeof lineItem.unitPrice !== 'undefined' && lineItem.unitPrice !== null) {
              if(typeof currencies[lineItem.unitPrice.currency_code] === 'undefined' || currencies[lineItem.unitPrice.currency_code] === null) {
                currencies[lineItem.unitPrice.currency_code] = {
                  subTotal: 0,
                  taxTotal: 0,
                  orderTotal: 0,
                  displayTitle: lineItem.unitPrice.taxRate ? lineItem.unitPrice.taxRate.displayTitle : null
                }
                if(typeof lineItem.unitPrice.taxRate !== 'undefined' && lineItem.unitPrice.taxRate !== null) {
                  currencies[lineItem.unitPrice.currency_code].displayTitle = lineItem.unitPrice.taxRate.displayTitle
                }
              }
              if(typeof lineItem.quantity === 'string') {
                lineItem.quantity = parseFloat(lineItem.quantity)
              }
              if(typeof lineItem.unitPrice.amount === 'string') {
                lineItem.unitPrice.amount = parseFloat(lineItem.unitPrice.amount)
              }
              if(typeof lineItem.quantity === 'string') {
                lineItem.quantity = parseFloat(lineItem.quantity)
              }

              let tax = 0
              if(typeof lineItem.unitPrice.taxRate !== 'undefined' && lineItem.unitPrice.taxRate !== null && typeof lineItem.unitPrice.taxRate.rate !== 'undefined' && lineItem.unitPrice.taxRate.rate !== null) {
                tax = lineItem.unitPrice.taxRate.rate * lineItem.unitPrice.amount * lineItem.quantity
              }
              currencies[lineItem.unitPrice.currency_code].subTotal += (lineItem.unitPrice.amount * lineItem.quantity - tax) * (1 - (lineItem.discountPercent / 100))
              currencies[lineItem.unitPrice.currency_code].taxTotal += tax  * (1 - (lineItem.discountPercent / 100))
              currencies[lineItem.unitPrice.currency_code].orderTotal += (lineItem.unitPrice.amount * lineItem.quantity)  * (1 - (lineItem.discountPercent / 100))
            }
          }

        }
        for(const currencyKey in currencies) {
          const currency =  currencies[currencyKey]
          
	        currency.subTotal = this.$store.getters.formatAmountByCurrency({
            amount: currency.subTotal,
            currency_code: currencyKey
          })
          
	        currency.taxTotal = this.$store.getters.formatAmountByCurrency({
            amount: currency.taxTotal,
            currency_code: currencyKey
          })
          
			currency.orderTotal = this.$store.getters.formatAmountByCurrency({
				amount: currency.orderTotal,
				currency_code: currencyKey
			})
        }
        return currencies
      }
    },
    mounted() {
      if(this.order === null && this.entangleKey !== null) {
        this.getOrder()
      }
      if(typeof this.order !== 'undefined' && this.order !== null) {
        this.tasks = this.getOrderTasks()
      }
    },
  watch: {
    order() {
      this.getOrderTasks()
    },
  }
}
</script>
